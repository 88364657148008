<template>
  <div id="account-edit">
    <HeaderNavigation title="mon compte - edition" />
    <b-container id="account-container" class="main-container">
      <h1>
        <button class="close" @click="$router.back()">
          <span>&times;</span>
        </button>
      </h1>
      <b-row>
        <b-col class="photo">
          <b-row>
            <b-col>
              <UserPhoto :photo="photo" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-file
                ref="photoInput"
                v-model="photo.file"
                class="fileInput"
                plain
                @input="onPhotoInput"
              />
              <b-button
                variant="dark"
                class="btn-modify"
                @click="$refs.photoInput.$el.click()"
                >MODIFIER</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form novalidate>
            <b-form-group label="NOM">
              <b-form-input
                type="text"
                v-model="userInfo.fullName"
                class="user-info"
              />
            </b-form-group>
            <b-form-group label="E-MAIL">
              <b-form-input
                type="text"
                v-model="userInfo.email"
                class="user-info"
                readonly
              />
            </b-form-group>
            <b-form-group label="PSEUDO">
              <b-form-input
                type="text"
                v-model="userInfo.username"
                class="user-info"
              />
            </b-form-group>
          </b-form>
          <b-button
            v-loader.disable="saving"
            class="btn-save float-right"
            variant="dark"
            :disabled="!isDirty"
            @click="saveUser"
          >
            SAUVER</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="id-card" :class="{ 'invalid-data': !isIdCardValid }">
            <b-row>
              <b-col cols="9">
                <b-card-title
                  title-tag="span"
                  class="title-headline text-uppercase"
                  >Pièce d'identité</b-card-title
                >
                <div v-if="!isIdCardValid">
                  <span class="badge badge-invalid-data">!</span>
                  &nbsp;
                  <span class="text-white">{{ idCardValidity }}</span>
                </div>
                <div v-else class="text-evenir-content-light">
                  {{ idCardValidity }}
                </div>
              </b-col>
              <b-col cols="3">
                <!-- TODO: Modify ID card -->
                <b-form-file
                  ref="idCardInput"
                  v-model="idCard.file"
                  class="fileInput"
                  plain
                  @input="onIdCardInput"
                />
                <b-button
                  variant="dark"
                  class="btn-modify"
                  @click="$refs.idCardInput.$el.click()"
                  >MODIFIER</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- TODO: Changement de mot de passe via ADB2C -->
      <!-- <b-row>
        <b-col>
          <b-form novalidate>
            <b-form-group label="MOT DE PASSE">
              <b-form-input type="password" v-model="newPassword" />
            </b-form-group>
            <b-form-group label="CONFIRMATION MOT DE PASSE">
              <b-form-input type="password" v-model="newPasswordConfirm" />
            </b-form-group>
          </b-form>
          <b-button variant="dark" @click="savePassword">SAUVER</b-button>
        </b-col>
      </b-row>-->
    </b-container>
  </div>
</template>
<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import UserPhoto from '@/components/Account/UserPhoto.vue'
import { mapActions } from 'vuex'
import { Routes } from '@/router.js'

export default {
  name: 'account-edit',
  components: { HeaderNavigation, UserPhoto },
  data() {
    return {
      userInfo: { ...this.$store.state.userInfo }, // Shallow copy
      photo: {
        file: undefined,
        type: undefined,
        url: undefined,
        error: false
      },
      idCard: {
        file: undefined,
        type: undefined,
        url: undefined,
        error: false
      },
      saving: false
    }
  },
  computed: {
    isValid() {
      return !!(
        this.userInfo &&
        this.userInfo.fullName &&
        this.userInfo.username
      )
    },
    isDirty() {
      const { fullName, username } = this.$store.state.userInfo

      return (
        !!this.photo.file ||
        this.userInfo.fullName != fullName ||
        this.userInfo.username != username ||
        !!this.idCard.file
      )
    },
    isIdCardValid() {
      return !!this.$store.state.documents.idCard.isValid
    },
    idCardValidity() {
      return this.$store.state.documents.idCard.isValid == undefined
        ? 'EN COURS DE VALIDATION'
        : this.isIdCardValid
        ? 'VALIDE'
        : 'INVALIDE'
    }
  },
  methods: {
    ...mapActions(['saveUserInfo', 'saveUserPhoto']),
    fillDocument(doc) {
      if (doc.file) {
        doc.type = doc.file.type
        doc.url = URL.createObjectURL(doc.file)
        doc.error = false
      }
    },
    resetDocument(doc) {
      doc.file = doc.type = doc.url = undefined
      doc.error = false
    },
    onPhotoInput() {
      this.fillDocument(this.photo)
    },
    onIdCardInput() {
      this.fillDocument(this.idCard)
    },
    saveUser() {
      this.saving = true
      const proms = []

      if (this.isValid) {
        proms.push(
          this.saveUserInfo({ userInfo: this.userInfo, api: this.$apiService })
            .then(() => true)
            .catch(err => {
              console.error(
                'Something went wrong when updating user info: ',
                err
              )

              return false
            })
        )
      }

      if (this.photo.file) {
        // TODO: Update store
        proms.push(
          this.saveUserPhoto({ photo: this.photo, api: this.$apiService })
            .then(() => this.resetDocument(this.photo))
            .then(() => true)
            .catch(err => {
              console.error(
                'Something went wrong when updating user photo: ',
                err
              )

              return false
            })
        )
      }

      if (this.idCard.file) {
        // TODO: Update store
        proms.push(
          this.$apiService
            .putUserIdCardAsync(this.idCard.file)
            .then(() => {
              this.resetDocument(this.idCard)
              this.$store.commit('setIdCard', this.idCard)

              return true
            })
            .catch(err => {
              console.error(
                'Something went wrong when updating user ID card: ',
                err
              )

              return false
            })
        )
      }

      Promise.all(proms)
        .then(results => {
          if (results.reduce((p, c) => p && c === true, true)) {
            this.$router.push(Routes.ACCOUNT)
          }
        })
        .finally(() => (this.saving = false))
    }
  }
}
</script>
<style lang="scss">
@import '@/styles.scss';

#account-edit {
  height: 100vh;
  background: #f8f8f8;

  h1 {
    position: relative;
    margin: 0;
    font-size: 16px;
    line-height: 16px;

    button.close {
      position: absolute;
      right: 0;
      font-weight: $font-weight-regular;
      font-size: 2em;
      line-height: unset;
      color: $dark;
      opacity: 1;
      z-index: 1;
    }
  }

  .col.photo {
    text-align: center;

    .profile-img {
      width: 96px;
      height: 96px;
    }
  }
  .fileInput {
    display: none;
  }
  .btn-modify {
    margin: 10px 0;
  }
  .btn-save {
    margin-bottom: 10px;
  }
  .form-control.usr-info {
    background: transparent;
  }
  .card.id-card {
    background-color: #efefef;
    border-radius: 10px;
    border: none;
    padding: 10px;
    &.invalid-data {
      background-color: $invalid-data-color;
      &:hover {
        .title-headline {
          color: #fff;
        }
      }
    }
    &:active {
      background-color: #ff995a;
    }
  }
}
</style>
